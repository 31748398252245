<template>
  <v-prd-date-picker-field v-model="compValue" :label="label" :rules="rules" dense is-birth-date />
</template>

<script>
export default {
  components: {
    vPrdDatePickerField: () => import("@/components/v-prd/DatePickerTextField"),
  },
  props: {
    value: { type: String, default: "" },
    options: { type: Object, required: true },
    schema: { type: Object, required: true },
    fullSchema: { type: Object, required: true },
    fullKey: { type: String, required: true },
    label: { type: String, default: "" },
    htmlDescription: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array, required: true },
    on: { type: Object, required: true },
  },
  computed: {
    compValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
